import React from 'react'
import Layout from '../components/layout'
import Feedback from '../components/feedback'

// Intentionally named without the final "k" to avoid name clashes.
class Feedbac extends React.Component {
  render() {
    return(
      <React.Fragment>
        <Layout>
          <Feedback />
        </Layout>
      </React.Fragment>
    );
  }
}

export default Feedbac
