import React from "react"

class Feedback extends React.Component {
  render() {
    return(
      <React.Fragment>
        <h3>Beta feedback</h3>
        <br />
        <strong>Known bugs:</strong>
        <ul>
          <li>There is a visual bug around the owners list on the "Details" page for any vault. Removing owners from the "Modify owners" section also removes them from the owners list at the top, even before you click "Update". This is purely a visual bug though, and the owners are not actually updated unless you click "Submit".</li>
        </ul>
        <br />
        <strong>Planned future improvements:</strong>
        <ul>
          <li>The login flow is currently a bit flakey and will be patched shortly.</li>
        </ul>
        <br />
        <strong>Feedback:</strong>
        <ul>
          <li>Please send any feedback directly to peter.giordano@york.ac.uk.</li>
        </ul>
    </React.Fragment>);
  }
}

export default Feedback
